import { Directive, ElementRef, HostListener, Input } from '@angular/core';

@Directive({
    selector: 'input[numbersOnly]'
})
export class NumbersOnlyDirective {

    @Input() isInputType: string;
    constructor(private _el: ElementRef) { }

    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = this._el.nativeElement.value;
        if (this.isInputType === 'phone') {
            this._el.nativeElement.value = initalValue.replace(/[^0-9+]*/g, '');
        } else if (this.isInputType === 'number') {
            this._el.nativeElement.value = initalValue.replace(/[^0-9]*/g, '');
        }
        if (initalValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

}


@Directive({
    selector: '[CharsOnly]'
})
export class CharsOnlyDirective {

    // @Input() isInputType: string;
    constructor(private _el: ElementRef) { }

    @HostListener('input', ['$event']) onInputChange(event) {
        const initalValue = this._el.nativeElement.value;
        this._el.nativeElement.value = initalValue.replace(/[^A-Za-z ]/g, '');
        if ( initalValue !== this._el.nativeElement.value) {
          event.stopPropagation();
        }
      }

    // @HostListener('input', ['$event']) onInputChange(event) {
    //     const initalValue = this._el.nativeElement.value;
    //     // if (this.isInputType === 'phone') {
    //         this._el.nativeElement.value = initalValue.replace(/^[a-zA-Z]*$/g, '');
    //     // } else if (this.isInputType === 'number') {
    //     //     this._el.nativeElement.value = initalValue.replace(/^[a-zA-Z]*$/g, '');
    //     // }
    //     if (initalValue !== this._el.nativeElement.value) {
    //         event.stopPropagation();
    //     }
    // }

}