import { Map } from '@app-core/map';
import { HashMap } from '@app-core/hashmap';
import { REST_URI } from '@app-core/constants';
import { REST_URI_MODULE } from '@app-core/constants';
import { BaseService } from '@app-core/base-service';
import { Injectable } from '@angular/core';
import { RestService } from './http-rest.service';
import { ValidationError } from '@app-models/validation-error';

@Injectable()
export class StudiesService extends BaseService {
  constructor(private restService: RestService) {
    super();
  }

  getStudiesList(data, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    const params: Map = new HashMap();
    params.put('limit', 25); // data.orgId);
    params.put('offset', 0); // data.userId);
    params.put('includefield', '');
    params.put('StudyDate', 0);
    params.put('orgId', data.orgId);
    params.put('userId', data.userId);
    params.put('isAll', 'no');
    this.restService.get(REST_URI.GETSTUDIESLIST, params).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  // getStudyListNew(orgid, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
  getStudyListNew(orgid, start?,pagesize?,search?,successCallBack?: (res: any) => void, errorCallBack?: (error: ValidationError) => void) {
    return this.restService.post(REST_URI.GETSTUDIESLISTNEW, {
      "orgId": orgid,
      "accessKey": "-arc%2Faets%2FDCM4CHEE%2Fwado%3FrequestType%3DWADO%",
      "pageNumber" : start+1, 
      "pageSize" : pagesize,
      "search" : search
    }).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  updateHeightWeight(data, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    return this.restService.post(REST_URI.UPDATESTUDYPATIENTINFO, {
      "patient_height": data.patient_height.toString(),
      "patient_weight": data.patient_weight.toString(),
      "studyInstanceUid": data.study_iuid,
      "accessKey": "-arc%2Faets%2FDCM4CHEE%2Fwado%3FrequestType%3DWADO%"
    }).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }


  saveComment(data, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    return this.restService.post(REST_URI.SAVECLINICALDETAILSCOMMENTS, {
      "studyInstanceUid": data.studyInstanceUid,
      "comment": data.comment,
      "accessKey": "-arc%2Faets%2FDCM4CHEE%2Fwado%3FrequestType%3DWADO%"
    }).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  // Clinical Detail api start

  deleteComments(data, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    return this.restService.post(REST_URI.DELETECLINICALDETAILCOMMENT, {
      "id": data.id,
      "accessKey": "-arc%2Faets%2FDCM4CHEE%2Fwado%3FrequestType%3DWADO%"
    }).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  getCommentList(data, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    return this.restService.post(REST_URI.GETCLINICALDETAILSCOMMENTS, {
      "studyInstanceUid": data.studyInstanceUid,
      "accessKey": "-arc%2Faets%2FDCM4CHEE%2Fwado%3FrequestType%3DWADO%"
    }).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  uploadAttachment(data, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    return this.restService.postWithFile(REST_URI.UPLOADATTACHMENT + data.studyInstanceUid, {
      "mutlipart": data.files,
    }).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  getClinicalDetailsFiles(data, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    return this.restService.post(REST_URI.GETCLINICALDETAILSFILES, {
      "studyInstanceUid": data.studyInstanceUid,
      "accessKey": "-arc%2Faets%2FDCM4CHEE%2Fwado%3FrequestType%3DWADO%"
    }).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  deleteClinicalDetailsFiles(data, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    return this.restService.post(REST_URI.DELETECLINICALDETAILFILE, {
      "id": data.id,
      "accessKey": "-arc%2Faets%2FDCM4CHEE%2Fwado%3FrequestType%3DWADO%"
    }).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }

  // Clinical Detail api start


  getUploadStudiesData(data, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    // const params: Map = new HashMap();
    //     params.put('orgId', 1); // data.orgId);
    //     params.put('userId', 1); //data.userId);
    this.restService.get(REST_URI.UPLOADSTUDIES + data.id + '/' + data.orgId + '/' + data.userId + '/no').subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }
  getStudyListAdmin(id, successCallBack: (res: any) => void, errorCallBack: (error: ValidationError) => void) {
    this.restService.get(REST_URI_MODULE.DASHBOARD + '/' + REST_URI.STUDIESDATA).subscribe(
      res => this.processSuccessResponse(res, successCallBack),
      (error: any) => this.processErrorResponse(error, errorCallBack));
  }
}
