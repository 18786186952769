import { OrgList } from './security-object';

export class UsersFormModel {
  public id: number;
  public name: string;
  public firstName: string;
  public lastName: string;
  public address1: string;
  public address2: string;
  public phoneNo: string;
  public email: string;
  public city: string;
  public state: string;
  public isActive: boolean;
  public pinCode: string;
  public orgId: number;
  public orgName: string;
  public roleId: number;
  public orgList: OrgList[];
  public roleName: string;
  public show?: boolean;
  public studyList?: any;
  public isConsultant?: boolean;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.address1 = data.address1;
    this.address2 = data.address1;
    this.phoneNo = data.phoneNo;
    this.email = data.email;
    this.city = data.city;
    this.state = data.state;
    this.isActive = data.isActive;
    this.pinCode = data.pinCode;
    this.orgId = data.orgId;
    this.orgName = data.orgName;
    this.roleId = data.roleId;
    this.orgList = data.orgList;
    this.roleName = data.roleName;
    this.show = data.show;
  }
}

export class RoleFormModel {
  public id: number;
  public name: string;
  public active: boolean;
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.active = data.active;
  }
}
